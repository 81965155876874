import { InputHTMLAttributes } from 'react';
import { FC } from 'react';

import ExclamationCircleIcon from '@heroicons/react/20/solid/ExclamationCircleIcon';
import cn from 'classnames';
import { FieldError } from 'react-hook-form';
import ReactPhoneInput, { Props } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import styles from './phone-input.module.scss';

interface PhoneInputProps extends Props<InputHTMLAttributes<HTMLInputElement>> {
  label?: string;
  helpText?: string;
  error?: FieldError;
  subLabel?: string;
  errorClassName?: string;
  inputClassName?: string;
  small?: boolean;
}

const PhoneInput: FC<PhoneInputProps> = ({
  label,
  helpText,
  error,
  className,
  subLabel,
  errorClassName,
  inputClassName,
  small = false,
  ...rest
}) => {
  return (
    <div className={cn(className)}>
      {label && (
        <label
          htmlFor={rest?.id}
          className="mb-2 block text-sm font-medium leading-6 text-gray-900"
        >
          <span>{label}</span>
          {subLabel && (
            <span className="ml-1 text-xs font-normal text-gray-500">
              {subLabel}
            </span>
          )}
        </label>
      )}

      <div className="relative rounded-md shadow-sm">
        <div className="relative">
          <ReactPhoneInput
            className={cn(styles.phone, inputClassName, {
              [styles.error]: !!error,
              [styles.default]: !error,
              [styles['phone-small']]: small,
            })}
            inputClassName={cn(inputClassName, {
              [styles.error]: !!error,
              [styles.default]: !error,
            })}
            defaultCountry="US"
            {...rest}
          />

          {error && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}
        </div>
      </div>
      {error && (
        <p
          className={cn('mt-2 text-sm text-red-600', errorClassName)}
          id="email-error"
        >
          {error.message}
        </p>
      )}
      {helpText && (
        <p className="mt-2 text-xs font-normal leading-4 text-gray-500">
          {helpText}
        </p>
      )}
    </div>
  );
};

export default PhoneInput;
