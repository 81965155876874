import { WorkspaceRole } from '@prisma/client';
import { useQuery } from '@tanstack/react-query';

import { getWorkspaceDetail } from '@/app/actions/getWorkspaceDetail';
import { getWorkspaceUsersById } from '@/app/actions/getWorkspaceUsers';

export type workspaceUsersResponse =
  | {
      id: string;
      email: string;
      name: string;
      avatar: string;
      role: WorkspaceRole;
    }[]
  | null;

type GetWorkspaceUsersParams = {
  workspaceId?: string;
};
const getWorkspaceUsers = async ({ workspaceId }: GetWorkspaceUsersParams) => {
  const workspace = await getWorkspaceDetail(workspaceId);
  const users = await getWorkspaceUsersById({ workspaceId: workspace.id });

  return users;
};

export function useWorkspaceUsers({ workspaceId }: GetWorkspaceUsersParams) {
  return useQuery<workspaceUsersResponse>({
    queryKey: ['workspace-users'],
    queryFn: () => getWorkspaceUsers({ workspaceId }),
  });
}
