'use client';

import { FC } from 'react';
import DownVoteFillIcon from '../icons/downvote-fill-icon.component';
import UpvoteFillIcon from '../icons/upvote-fill-icon.component';

export type QualityBadgeType = 'GOOD' | 'BAD';

export type QualityBadgeProps = {
  value: QualityBadgeType;
};

const QualityBadge: FC<QualityBadgeProps> = ({ value }) => {
  if (value === 'GOOD') {
    return (
      <div className="w-max flex items-center justify-center bg-white text-green-800 rounded-full py-[6px] px-[14px]">
        <UpvoteFillIcon className="w-4 h-4" />
        <span className="ml-1.5 text-xs leading-normal">Good response</span>
      </div>
    );
  }

  if (value === 'BAD') {
    return (
      <div className="w-max flex items-center justify-center bg-white text-red-800 rounded-full py-[6px] px-[14px]">
        <DownVoteFillIcon className="w-4 h-4" />
        <span className="ml-1.5 text-xs leading-normal">Bad response</span>
      </div>
    );
  }

  return null;
};

export default QualityBadge;
