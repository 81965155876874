import useTranslationStore from '@/store/translation';
import Link from 'next/link';
import cn from 'classnames';
import { FC } from 'react';

type MessengerFooterProps = {
  className?: string;
  removeBranding?: boolean;
};
const MessengerFooter: FC<MessengerFooterProps> = ({
  className,
  removeBranding,
}) => {
  const { getValue } = useTranslationStore();
  return (
    <div
      id="messenger-footer"
      className={cn('bg-[#F5F5F5] py-[6px] px-[15px] h-[22px]', className)}
    >
      {removeBranding ? null : (
        <Link
          href="https://livechatai.com/"
          target="_blank"
          className="block text-[8px] leading-[10px] text-[#777777] text-center"
        >
          Powered by LiveChatAI
        </Link>
      )}
    </div>
  );
};

export default MessengerFooter;
