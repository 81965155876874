function getRGB(c: string) {
  return parseInt(c, 16);
}

function getsRGB(c: string) {
  return getRGB(c) / 255 <= 0.03928
    ? getRGB(c) / 255 / 12.92
    : Math.pow((getRGB(c) / 255 + 0.055) / 1.055, 2.4);
}

function getLuminance(hexColor: string, hexBackground = '#FFFFFF') {
  const r = getsRGB(hexColor.substring(1, 3));
  const g = getsRGB(hexColor.substring(3, 5));
  const b = getsRGB(hexColor.substring(5, 7));
  const alpha =
    hexColor.length === 9 ? parseInt(hexColor.substring(7, 9), 16) / 255 : 1;

  const rb = getsRGB(hexBackground.substring(1, 3));
  const gb = getsRGB(hexBackground.substring(3, 5));
  const bb = getsRGB(hexBackground.substring(5, 7));

  const luminanceForeground = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  const luminanceBackground = 0.2126 * rb + 0.7152 * gb + 0.0722 * bb;

  return alpha * luminanceForeground + (1 - alpha) * luminanceBackground;
}

export function getContrast(f: string, b: string) {
  const L1 = getLuminance(f);
  const L2 = getLuminance(b);
  return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
}

export function getTextColor(bgColor: string) {
  const whiteContrast = getContrast(bgColor, '#ffffff');
  const blackContrast = getContrast(bgColor, '#000000');
  const diff = Math.abs(whiteContrast - blackContrast);
  const color = whiteContrast > blackContrast ? '#ffffff' : '#000000';

  return diff < 4 ? '#ffffff' : color;
}

export function addAlpha(color: string, opacity: number) {
  // coerce values so ti is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

export const hexToRgb = (hex: string) => {
  try {
    const rgb = hex
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m: string, r: string, g: string, b: string) =>
          '#' + r + r + g + g + b + b,
      )
      .substring(1)
      .match(/.{2}/g)
      .map((x) => parseInt(x, 16));

    return {
      r: rgb[0],
      g: rgb[1],
      b: rgb[2],
    };
  } catch (error) {
    return hex;
  }
};
