import { FC } from 'react';
import cn from 'classnames';
import { SvgProps } from './types';

const DownVoteIcon: FC<SvgProps> = ({
  className,
  width = '18',
  height = '18',
  viewBox = '0 0 18 18',
}) => {
  return (
    <svg
      className={cn(className)}
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.6235 11.4375H3.279C2.5095 11.4375 1.82025 10.917 1.7385 10.1513C1.70447 9.83113 1.68745 9.50943 1.6875 9.1875C1.6875 7.0515 2.4315 5.0895 3.67425 3.54675C3.96525 3.18525 4.4145 3 4.878 3H7.89C8.25271 2.99978 8.61308 3.05803 8.95725 3.1725L11.2928 3.9525C11.6369 4.06698 11.9973 4.12522 12.36 4.125H13.3305M5.6235 11.4375C6.087 11.4375 6.36675 11.9805 6.16725 12.399C5.80896 13.1531 5.62369 13.9777 5.625 14.8125C5.625 15.2601 5.80279 15.6893 6.11926 16.0057C6.43573 16.3222 6.86495 16.5 7.3125 16.5C7.46168 16.5 7.60476 16.4407 7.71025 16.3352C7.81574 16.2298 7.875 16.0867 7.875 15.9375V15.4628C7.875 15.033 7.9575 14.6078 8.1165 14.2088C8.3445 13.6388 8.814 13.2113 9.35625 12.9225C10.1887 12.4783 10.9193 11.8652 11.5013 11.1225C11.8748 10.647 12.4208 10.3125 13.0253 10.3125H13.3132M5.6235 11.4375H7.275M13.3305 4.125C13.338 4.1625 13.3515 4.2 13.3688 4.236C13.8135 5.136 14.0625 6.1485 14.0625 7.21875C14.0625 8.334 13.7925 9.38625 13.3132 10.3125M13.3305 4.125C13.2735 3.85125 13.4678 3.5625 13.7618 3.5625H14.4427C15.1095 3.5625 15.7275 3.951 15.9218 4.5885C16.176 5.421 16.3125 6.30375 16.3125 7.21875C16.3125 8.3835 16.0913 9.49575 15.6893 10.5173C15.4598 11.0978 14.8747 11.4375 14.2507 11.4375H13.461C13.107 11.4375 12.9022 11.0205 13.086 10.7175C13.1664 10.5852 13.2422 10.4501 13.3132 10.3125"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DownVoteIcon;
