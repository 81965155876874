import * as React from 'react';

const SendNextBlackIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M2.898 2.004a.625.625 0 0 0-.771.784l2.026 6.587h7.097a.625.625 0 0 1 0 1.25H4.153l-2.026 6.587a.625.625 0 0 0 .771.784 50.43 50.43 0 0 0 15.371-7.488.625.625 0 0 0 0-1.015A50.431 50.431 0 0 0 2.9 2.003Z"
    />
  </svg>
);
export default SendNextBlackIcon;
