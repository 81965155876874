import Image from 'next/image';
import React, { FC } from 'react';
import cn from 'classnames';

import AILogo from '@public/assets/images/ai-agent.png';

type AvatarProps = {
  className?: string;
  defaultAIAvatar?: boolean;
  src?: string;
  email?: string;
  size?: number;
  renderOnlineBadge?: boolean;
  onlineStatus?: boolean;
};

const Avatar: FC<AvatarProps> = ({
  className,
  src,
  email,
  size = 32,
  renderOnlineBadge = false,
  defaultAIAvatar = false,
  onlineStatus = false,
}) => {
  const _src = defaultAIAvatar ? src || AILogo.src : src;

  if (_src) {
    return (
      <div
        className="relative inline-flex"
        style={{
          width: `${size}px`,
          height: `${size}px`,
          minWidth: `${size}px`,
          minHeight: `${size}px`,
        }}
      >
        <Image
          data-testid="image-avatar"
          className={cn(
            'aspect-square',
            {
              ['rounded-full object-cover object-center']: !defaultAIAvatar,
            },
            className,
          )}
          width={size}
          height={size}
          src={_src}
          alt={email || 'Avatar'}
        />

        {renderOnlineBadge ? (
          <span
            className={cn(
              'absolute right-0 top-0 h-[6px] w-[6px] rounded-full ring-2 ring-white',
              {
                ['bg-green-500']: onlineStatus,
                ['bg-red-500']: !onlineStatus,
              },
            )}
          />
        ) : null}
      </div>
    );
  }

  return (
    <div
      data-testid="default-avatar"
      className={cn(
        className,
        'relative inline-flex aspect-square items-center justify-center rounded-full bg-gray-200',
      )}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        minWidth: `${size}px`,
        minHeight: `${size}px`,
      }}
    >
      <span
        data-testid="default-avatar-text"
        className="font-medium capitalize leading-none text-gray-800"
        style={{ fontSize: `${size / 2}px` }}
      >
        {email?.charAt(0) || '?'}
      </span>

      {renderOnlineBadge ? (
        <span
          className={cn(
            'absolute right-0 top-0 h-[6px] w-[6px] rounded-full ring-2 ring-white',
            {
              ['bg-green-500']: onlineStatus,
              ['bg-red-500']: !onlineStatus,
            },
          )}
        />
      ) : null}
    </div>
  );
};

export default Avatar;
