import {
  DEFAULT_ENGLISH,
  DEFAULT_GERMAN,
  DEFAULT_SPANISH,
  DEFAULT_TURKISH,
  TranslationsFields,
} from '@/constants/translation';
import { LanguageCodes } from '@/constants/language-codes-list';
export const getDefaultTranslationValues = (
  language: string,
): TranslationsFields => {
  const languageMap = {
    ['ENGLISH']: DEFAULT_ENGLISH,
    ['SPANISH']: DEFAULT_SPANISH,
    ['GERMAN']: DEFAULT_GERMAN,
    ['TURKISH']: DEFAULT_TURKISH,
  };
  return languageMap[language] || DEFAULT_ENGLISH;
};

export const getLanguageCode = (language: string): string => {
  return LanguageCodes[language] || 'en';
};

export type NonDefaultFields = Array<{
  field: keyof TranslationsFields;
  value: string;
}>;
export const getNonDefaultTranslation = (
  fields: TranslationsFields,
  language: string,
): NonDefaultFields => {
  const defaultFields = getDefaultTranslationValues(language);
  const fieldsToCompare = Object.keys(defaultFields);

  const differentTranslations = [];

  fieldsToCompare.forEach((field) => {
    if (defaultFields[field] !== fields[field]) {
      differentTranslations.push({
        field,
        value: fields[field],
      });
    }
  });

  return differentTranslations;
};
