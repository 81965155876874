import { FC } from 'react';
import cn from 'classnames';
import { SvgProps } from './types';

const UpvoteIcon: FC<SvgProps> = ({
  className,
  width = '18',
  height = '18',
  viewBox = '0 0 18 18',
}) => {
  return (
    <svg
      className={cn(className)}
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.97475 7.6875C5.57925 7.6875 6.1245 7.353 6.498 6.8775C7.08019 6.13471 7.81103 5.52164 8.64375 5.0775C9.186 4.7895 9.65625 4.3605 9.8835 3.79125C10.0431 3.39244 10.1251 2.96681 10.125 2.53725V2.0625C10.125 1.91332 10.1843 1.77024 10.2898 1.66475C10.3952 1.55926 10.5383 1.5 10.6875 1.5C11.1351 1.5 11.5643 1.67779 11.8807 1.99426C12.1972 2.31072 12.375 2.73995 12.375 3.1875C12.375 4.0515 12.18 4.86975 11.8328 5.601C11.6333 6.0195 11.913 6.5625 12.3765 6.5625M12.3765 6.5625H14.721C15.4905 6.5625 16.1798 7.083 16.2615 7.84875C16.2953 8.16525 16.3125 8.48625 16.3125 8.8125C16.3156 10.8648 15.6143 12.8559 14.3258 14.4532C14.0348 14.8147 13.5855 15 13.122 15H10.11C9.74775 15 9.387 14.9415 9.04275 14.8275L6.70725 14.0475C6.36308 13.933 6.00271 13.8748 5.64 13.875H4.428M12.3765 6.5625H10.6875M4.428 13.875C4.49025 14.0287 4.55775 14.1787 4.6305 14.3265C4.77825 14.6265 4.572 15 4.23825 15H3.55725C2.8905 15 2.2725 14.6115 2.07825 13.974C1.81849 13.1214 1.6868 12.235 1.6875 11.3438C1.6875 10.179 1.90875 9.06675 2.31075 8.04525C2.54025 7.46475 3.12525 7.125 3.75 7.125H4.53975C4.89375 7.125 5.0985 7.542 4.91475 7.845C4.27417 8.89946 3.93632 10.11 3.93825 11.3438C3.93825 12.2393 4.11225 13.0935 4.42875 13.875H4.428Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UpvoteIcon;
