'use client';

import type { PublicLinks } from './types';

import { FC, useState } from 'react';
import { ContentSource } from '@prisma/client';
import Link from 'next/link';
import cn from 'classnames';

import ChevronRightIcon from '@heroicons/react/20/solid/ChevronRightIcon';
import useTranslationStore from '@/store/translation';

import RelevanceScore from './relevance-score.component';
import { MessageBoxStyle, MessegeBoxVariant } from './message-box.component';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { cleanAndDeduplicatePublicLinks } from '@/utils/message';

type LearnMoreProps = {
  className?: string;
  position: 'left' | 'right';
  source?: PublicLinks;
  variant: MessegeBoxVariant;
  style: MessageBoxStyle;
};

const LearnMore: FC<LearnMoreProps> = ({
  className,
  position,
  source,
  variant,
  style: { textColor, borderColor },
}) => {
  const [showSource, setShowSource] = useState<boolean>(false);

  const { getValue } = useTranslationStore();

  const publicLinks = cleanAndDeduplicatePublicLinks(source);

  if (variant === 'minimal') {
    return (
      <div
        className={cn(
          'grid grid-cols-[16px_1fr] gap-1.5 pt-3 px-[18px] pb-[15px]',
          className,
        )}
        style={{
          color: textColor,
          borderTop: `1px solid ${borderColor}`,
        }}
      >
        <InformationCircleIcon
          className={cn('w-4 h-4', {
            ['opacity-70']: !showSource,
          })}
        />
        <div className="w-full">
          <button
            className={cn(
              'flex items-center justify-between w-full cursor-pointer text-xs font-medium',
              {
                ['opacity-70']: !showSource,
              },
            )}
            onClick={() => setShowSource(!showSource)}
          >
            {getValue('learnMore')}
            <ChevronRightIcon
              className={cn('mr-0.5 h-4 w-4 transition-transform', {
                ['-rotate-90']: showSource,
                ['rotate-90']: !showSource,
              })}
              aria-hidden="true"
            />
          </button>
          {showSource && (
            <div className={cn('grid grid-cols-1 gap-1.6 mt-2')}>
              {publicLinks.map((item, index) => {
                if (item.type === ContentSource.WEBSITE) {
                  return (
                    <Link
                      className="inline-flex w-auto items-center justify-start py-1 text-xs transition-transform hover:-translate-y-0.5"
                      key={index}
                      href={item.link}
                      target="_blank"
                    >
                      <span className="inline-block truncate w-auto mr-1.5">
                        {item.link}
                      </span>
                      ↗
                    </Link>
                  );
                }

                return (
                  <span
                    className="inline-block truncate py-1 text-xs transition-transform hover:-translate-y-0.5"
                    key={index}
                  >
                    {item.link}
                  </span>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={cn(className)}>
      <button
        className={cn(
          'flex items-center cursor-pointer text-xs font-medium text-gray-500',
          {
            ['mr-auto']: position === 'left',
            ['ml-auto flex-row-reverse']: position === 'right',
          },
        )}
        onClick={() => setShowSource(!showSource)}
      >
        {getValue('learnMore')}
        <ChevronRightIcon
          className={cn('mr-0.5 h-4 w-4 transition-transform', {
            ['rotate-90']: showSource,
            ['rotate-0']: !showSource && position === 'left',
            ['rotate-180']: !showSource && position === 'right',
          })}
          aria-hidden="true"
        />
      </button>
      {showSource && (
        <div
          className={cn('flex flex-wrap items-center gap-2 mt-2', {
            ['justify-end']: position === 'right',
            ['justify-start']: position === 'left',
          })}
        >
          {publicLinks.map((item, index) => {
            if (item.type === ContentSource.WEBSITE) {
              return (
                <Link
                  className="inline-grid sm:grid-cols-[1fr_auto] gap-2 place-items-end rounded border border-gray-200 bg-white px-2 py-1 text-xs text-gray-500 transition-transform hover:-translate-y-0.5"
                  key={index}
                  href={item.link}
                  target="_blank"
                >
                  <span className="inline-block truncate my-auto w-full">
                    {item.link}
                  </span>
                  <RelevanceScore distance={item?.distance} />
                </Link>
              );
            }

            return (
              <span
                className="inline-grid sm:grid-cols-[1fr_auto] gap-2 rounded border border-gray-200 bg-white px-2 py-1 text-xs text-gray-500 transition-transform hover:-translate-y-0.5"
                key={index}
              >
                <span className="inline-block truncate my-auto w-full">
                  {item.link}
                </span>
                <RelevanceScore distance={item?.distance} />
              </span>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default LearnMore;
