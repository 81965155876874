import { FC } from 'react';
import cn from 'classnames';
import { SvgProps } from './types';

const DownVoteFillIcon: FC<SvgProps> = ({
  className,
  width = '18',
  height = '18',
  viewBox = '0 0 18 18',
}) => {
  return (
    <svg
      className={cn(className)}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M11.7975 4.125H12.5737C13.1797 5.04289 13.5018 6.11891 13.5 7.21875C13.5018 8.31859 13.1797 9.39461 12.5737 10.3125H12.4628C11.8583 10.3125 11.3123 10.647 10.9395 11.1225C10.3573 11.8653 9.62648 12.4784 8.79375 12.9225C8.2515 13.2105 7.78125 13.6395 7.554 14.2088C7.39441 14.6076 7.31245 15.0332 7.3125 15.4628V15.9375C7.3125 16.0867 7.25324 16.2298 7.14775 16.3352C7.04226 16.4407 6.89918 16.5 6.75 16.5C6.30245 16.5 5.87323 16.3222 5.55676 16.0057C5.24029 15.6893 5.0625 15.2601 5.0625 14.8125C5.0625 13.9485 5.2575 13.1302 5.60475 12.399C5.80425 11.9805 5.5245 11.4375 5.061 11.4375H2.7165C1.947 11.4375 1.25775 10.917 1.176 10.1513C1.14197 9.83113 1.12495 9.50943 1.125 9.1875C1.125 7.0515 1.869 5.0895 3.11175 3.54675C3.40275 3.18525 3.852 3 4.3155 3H7.3275C7.69021 2.99978 8.05058 3.05803 8.39475 3.1725L10.7303 3.9525C11.0744 4.06698 11.4348 4.12522 11.7975 4.125ZM16.2518 10.5173C16.6538 9.49575 16.875 8.3835 16.875 7.21875C16.875 6.30375 16.7385 5.42025 16.485 4.5885C16.29 3.951 15.672 3.5625 15.0052 3.5625H14.325C13.9913 3.5625 13.785 3.936 13.9327 4.236C14.376 5.136 14.6257 6.1485 14.6257 7.21875C14.6278 8.45279 14.29 9.66357 13.6493 10.7183C13.4655 11.0205 13.6702 11.4375 14.0243 11.4375H14.814C15.438 11.4375 16.0223 11.0978 16.2518 10.5173Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DownVoteFillIcon;
