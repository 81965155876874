import { FC } from 'react';
import cn from 'classnames';
import { SvgProps } from './types';

const MessageBubbleIcon: FC<SvgProps> = ({
  className,
  width = '16',
  height = '15',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={cn(className)}
      width={width}
      height={height}
      viewBox="0 0 16 15"
      fill="none"
    >
      <path
        d="M3.51001 14.5C3.34001 14.5 3.16001 14.5 2.98001 14.47C2.84001 14.46 2.71001 14.44 2.57001 14.41C2.31001 14.36 2.09001 14.18 1.99001 13.93C1.90001 13.68 1.95001 13.39 2.12001 13.19C2.41001 12.85 2.60001 12.44 2.69001 12.01C2.69001 11.98 2.66001 11.88 2.55001 11.77C1.22001 10.48 0.490005 8.78 0.490005 6.99C0.490005 3.16 3.85001 0.0500031 7.99001 0.0500031C12.13 0.0500031 15.49 3.16 15.49 6.99C15.49 10.82 12.13 13.93 7.99001 13.93H7.98001C7.39001 13.93 6.79001 13.86 6.21001 13.73C5.39001 14.23 4.45001 14.49 3.50001 14.49L3.51001 14.5ZM8.00001 1.56C4.69001 1.56 2.00001 4 2.00001 7C2.00001 8.38 2.57001 9.69 3.61001 10.7C4.08001 11.16 4.28001 11.74 4.17001 12.3C4.13001 12.53 4.06001 12.75 3.98001 12.97C4.58001 12.89 5.15001 12.67 5.65001 12.32C5.83001 12.19 6.06001 12.15 6.28001 12.21C6.84001 12.36 7.41001 12.44 7.99001 12.44H8.00001C11.31 12.44 14 10 14 7C14 4 11.31 1.56 8.00001 1.56ZM10.81 8.03C10.54 8.03 10.27 7.92 10.08 7.73C9.89001 7.54 9.78001 7.28 9.78001 7C9.78001 6.72 9.89001 6.47 10.08 6.27C10.46 5.89 11.15 5.89 11.54 6.27C11.73 6.46 11.84 6.72 11.84 7C11.84 7.28 11.73 7.53 11.54 7.73C11.35 7.92 11.08 8.03 10.81 8.03ZM8.00001 8.03C7.72001 8.03 7.47001 7.92 7.27001 7.73C7.07001 7.54 6.97001 7.28 6.97001 7C6.97001 6.72 7.08001 6.47 7.27001 6.27C7.66001 5.88 8.34001 5.88 8.73001 6.27C8.92 6.46 9.03001 6.72 9.03001 7C9.03001 7.28 8.92 7.53 8.73001 7.73C8.54001 7.93 8.28001 8.03 8.00001 8.03ZM5.19001 8.03C4.91001 8.03 4.66001 7.92 4.46001 7.73C4.26001 7.54 4.16001 7.28 4.16001 7C4.16001 6.72 4.27001 6.47 4.46001 6.27C4.85001 5.88 5.53001 5.88 5.92001 6.27C6.11001 6.46 6.22001 6.72 6.22001 7C6.22001 7.28 6.11001 7.53 5.92001 7.73C5.73001 7.93 5.47001 8.03 5.19001 8.03Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MessageBubbleIcon;
