'use client';

import { FC } from 'react';
import cn from 'classnames';

import { distanceToRelevanceScore } from '@/utils/message';

type RelevanceScoreProps = {
  className?: string;
  distance: number | undefined | null;
};

const RelevanceScore: FC<RelevanceScoreProps> = ({ className, distance }) => {
  if (!distance) return null;
  if (typeof distance !== 'number') return null;

  return (
    <span
      className={cn(
        'inline-flex items-center rounded-md px-1.5 py-1 ring-1 ring-inset bg-blue-100 text-blue-600 ring-blue-700/10',
        className,
      )}
    >
      Relevance Score:
      {distanceToRelevanceScore(distance)}
    </span>
  );
};

export default RelevanceScore;
