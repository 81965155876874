import * as React from 'react';

const SendNextIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M5 10 2.724 2.605A49.806 49.806 0 0 1 17.904 10a49.808 49.808 0 0 1-15.18 7.397L5 10Zm0 0h6.25"
    />
  </svg>
);
export default SendNextIcon;
