import { FC } from 'react';
import cn from 'classnames';
import { SvgProps } from './types';

const UpvoteFillIcon: FC<SvgProps> = ({
  className,
  width = '18',
  height = '18',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={cn(className)}
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M5.61976 13.875C5.30101 13.875 5.00476 13.698 4.88851 13.401C4.63106 12.7457 4.49927 12.0478 4.50001 11.3438C4.50001 10.0312 4.94926 8.82525 5.70151 7.86825C5.81476 7.72425 5.98126 7.6365 6.15151 7.5705C6.50626 7.43325 6.81901 7.185 7.06051 6.8775C7.64272 6.13473 8.37355 5.52166 9.20626 5.0775C9.74851 4.7895 10.2188 4.3605 10.446 3.79125C10.6056 3.39244 10.6876 2.96681 10.6875 2.53725V2.0625C10.6875 1.91332 10.7468 1.77024 10.8523 1.66475C10.9577 1.55926 11.1008 1.5 11.25 1.5C11.6976 1.5 12.1268 1.67779 12.4433 1.99426C12.7597 2.31072 12.9375 2.73995 12.9375 3.1875C12.9375 4.0515 12.7425 4.86975 12.3953 5.601C12.1958 6.0195 12.4755 6.5625 12.939 6.5625H15.2835C16.053 6.5625 16.7423 7.083 16.824 7.84875C16.8578 8.16525 16.875 8.48625 16.875 8.8125C16.8781 10.8648 16.1768 12.8559 14.8883 14.4532C14.5973 14.8147 14.148 15 13.6845 15H10.6725C10.3103 15 9.94951 14.9415 9.60526 14.8275L7.26976 14.0475C6.92558 13.933 6.56522 13.8748 6.20251 13.875H5.61976ZM1.74826 8.04525C1.33495 9.09578 1.1235 10.2148 1.12501 11.3438C1.12406 12.235 1.25549 13.1214 1.51501 13.974C1.71001 14.6115 2.32876 15 2.99551 15H3.67501C4.00876 15 4.21501 14.6265 4.06726 14.3265C3.60986 13.3988 3.37271 12.3781 3.37426 11.3438C3.37426 10.0627 3.73126 8.865 4.35076 7.84425C4.53451 7.542 4.32976 7.125 3.97576 7.125H3.18751C2.56351 7.125 1.97776 7.46475 1.74826 8.04525Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default UpvoteFillIcon;
